@import 'colors';
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: 700;
}

.text-medium {
  font-weight: 500;
}

.text-regular {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

.flex-item {
  display: flex;
}

.table-container {
  max-width: 100%;
  overflow-x: scroll;
}
.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 30px;
}

:root {
  --primary: #00479d;
  --dark-primary: #0a58ca;
  --light-primary: #3180f4;
  --light-grey: #dbe9ff;
  --background: #edeff7;
  --neutral-3: #a7b0ba;
  --white: #ffffff;
}

$colors: (
  'primary': '#00479D',
  'danger': '#FD4A1E',
  'red': '#FD4A1E',
  'success': '#8ADE60',
  'green': '#8ADE60',
  'warn': '#FF9A3C',
  'orange': '#FF9A3C',
  'grey': '#A7B0BA',
  'darkgrey': '#4F6176',
  'white': '#fff',
  'black': '#031D3B',
  'transparent': 'transparent',
);
@each $color, $var in $colors {
  .bg-#{$color} {
    background-color: #{$var};
  }
  .cl-#{$color} {
    color: #{$var};
  }
}

.shadow-1 {
  box-shadow: $shadow-1;
}

.shadow-2 {
  box-shadow: $shadow-2;
}

// Space
$sizes: 0, 4, 8, 10, 12, 16, 18, 19, 20, 24, 26, 28, 32, 36, 40, 60;
@each $size in $sizes {
  .mb-#{$size} {
    margin-bottom: $size + px !important;
  }
  .mt-#{$size} {
    margin-top: $size + px !important;
  }
  .ml-#{$size} {
    margin-left: $size + px !important;
  }
  .mr-#{$size} {
    margin-right: $size + px !important;
  }
  .mx-#{$size} {
    margin-left: $size + px !important;
    margin-right: $size + px !important;
  }
  .my-#{$size} {
    margin-top: $size + px !important;
    margin-bottom: $size + px !important;
  }
  .pb-#{$size} {
    padding-bottom: $size + px !important;
  }
  .pt-#{$size} {
    padding-top: $size + px !important;
  }
  .pl-#{$size} {
    padding-left: $size + px !important;
  }
  .pr-#{$size} {
    padding-right: $size + px !important;
  }
  .p-#{$size} {
    padding: $size + px !important;
  }
  .py-#{$size} {
    padding-top: $size + px !important;
    padding-bottom: $size + px !important;
  }
  .px-#{$size} {
    padding-left: $size + px !important;
    padding-right: $size + px !important;
  }
  [rowgap-#{$size}] {
    display: inline-flex;
    flex-wrap: wrap;
    gap: $size + px;
  }
}

$sizep: 0, 4, 6, 8, 10, 12, 16;
@each $size in $sizep {
  .br-#{$size} {
    border-radius: $size + px;
  }
  .bdr-#{$size} {
    border-radius: $size + px !important;
  }
  .br-tl-#{$size} {
    border-top-left-radius: $size + px !important;
  }
  .br-bl-#{$size} {
    border-bottom-left-radius: $size + px !important;
  }
  .br-tr-#{$size} {
    border-top-right-radius: $size + px !important;
  }
  .br-br-#{$size} {
    border-top-right-radius: $size + px !important;
  }
}

// Width
$widthpx: 0, 1, 4, 8, 10, 12, 16, 20, 24, 28, 30, 32, 36, 40, 44, 48, 50, 52, 56, 60, 64, 68, 70, 72, 80, 90, 96, 100,
  120, 140, 150, 160, 180, 200, 220, 240, 260, 280, 300, 400;
@each $size in $widthpx {
  .w-#{$size}px {
    width: $size + px !important;
  }
  .h-#{$size}px {
    height: $size + px !important;
  }
}

$widthpercen: 0, 1, 3, 4, 5, 8, 10, 12, 15, 18, 20, 30, 31, 40, 50, 60, 70, 80, 90, 100;
@each $size in $widthpercen {
  .w-#{$size} {
    width: #{$size + '%'};
  }
}

// Border
.bd-no {
  border: none !important;
}

.bd-top {
  border-top: 1px solid $neutral-4;
}

.bd-bottom {
  border-bottom: 1px solid $neutral-4;
}

.bd-right {
  border-right: 1px solid $neutral-4;
}

.bd-left {
  border-left: 1px solid $neutral-4;
}

// Base
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

[hidden] {
  display: none !important;
}
