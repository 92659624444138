@import 'colors';
// New
mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 16px;
    .mat-form-field-flex {
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      .mat-form-field-infix {
        padding-top: 18px;
        padding-bottom: 2px;
        border-top: 0px solid transparent;
        .mat-input-element {
          margin-top: 0;
          min-height: 20px;
        }
        .mat-form-field-label-wrapper {
          top: 0;
          padding-top: 16px;
          .mat-form-field-label {
            top: 0;
            padding-top: 0;
            transform: none !important;
            margin-top: 0;
            color: $neutral-2;
            .mat-placeholder-required {
              color: $red-1 !important;
            }
          }
        }
      }
      &:hover {
        & + .mat-form-field-underline {
          .mat-form-field-ripple {
            background-color: $primary-3;
          }
        }
      }
    }
    .mat-form-field-underline {
      bottom: 16px;
      &:before {
        background-color: $neutral-3;
      }
    }
  }
  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background-color: transparent;
        .mat-input-element {
          &:disabled {
            color: $neutral-1;
          }
        }
      }
      .mat-form-field-underline {
        bottom: 16px;
        &:before {
          background-color: transparent;
          background-repeat: repeat-x;
          background-size: 4px 1px;
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
        }
      }
    }
  }

  // INPUT LABEL
  &.label-input {
    padding-top: 18px;
    .mat-form-field-wrapper {
      padding-bottom: 0;
      width: 100%;
      .mat-form-field-flex {
        .mat-form-field-infix {
          border: 1px solid $neutral-4;
          border-radius: 4px;
          padding: 7px 8px;
          box-sizing: border-box;
          .mat-select {
            // height: 20px;
            .mat-select-trigger {
              height: 20px;
            }
          }
          .mat-form-field-label-wrapper {
            padding-top: 0;
            top: -18px;
          }
          .mat-select-arrow-wrapper {
            transform: none !important;
          }
        }
      }
      .mat-form-field-underline {
        display: none !important;
      }
    }
    &.mat-focused {
      .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-flex {
          .mat-form-field-infix {
            border-color: $primary-2;
          }
        }
      }
    }
  }
}
