@import 'colors';
.mc-icon {
  display: block;
  width: 20px;
  height: 20px;
  background-color: $neutral-3;
  mask-image: url(/assets/images/icons/dashboard.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  &.mci-company {
    mask-image: url(/assets/images/icons/company.svg);
  }
  &.mci-kpi {
    mask-image: url(/assets/images/icons/kpi.svg);
  }
  &.mci-dashboard {
    mask-image: url(/assets/images/icons/dashboard.svg);
  }
  &.mci-exits {
    mask-image: url(/assets/images/icons/exits.svg);
  }
  &.mci-timesheet {
    mask-image: url(/assets/images/icons/timesheet.svg);
  }
  &.user-star {
    mask-image: url(/assets/images/icons/user-star.svg);
  }
  &.mci-daotao {
    mask-image: url(/assets/images/icons/daotao.svg);
  }
  &.mci-tuyendung {
    mask-image: url(/assets/images/icons/tuyendung.svg);
  }
  &.mci-loitat {
    mask-image: url(/assets/images/icons/loitat.svg);
  }
  &.mci-salary {
    mask-image: url(/assets/images/icons/salary.svg);
  }
  &.mci-insurance {
    mask-image: url(/assets/images/icons/insurance.svg);
  }
  &.mci-onboarding {
    mask-image: url(/assets/images/icons/handshake.svg);
  }
  &.mci-luongDN {
    mask-image: url(/assets/images/icons/luong.svg);
  }
  &.mci-employee {
    mask-image: url(/assets/images/icons/employee.svg);
  }
  &.mci-setting {
    mask-image: url(/assets/images/icons/setting.svg);
  }
  &.mci-recruitment {
    mask-image: url(/assets/images/icons/recruitment.svg);
  }
  &.mci-service {
    mask-image: url(/assets/images/icons/service.svg);
  }
  &.mci-duyetthongtin_nhansu {
    mask-image: url(/assets/images/icons/duyetthongtin_nhansu.svg);
  }
  &.mci-kehoachnhansu {
    mask-image: url(/assets/images/icons/kehoachnhansu.svg);
  }
  &.mci-luanchuyen_phattrien {
    mask-image: url(/assets/images/icons/luanchuyen_phattrien.svg);
  }
  &.mci-organization_new {
    mask-image: url(/assets/images/icons/organization_new.svg);
  }
  &.mci-qhe_laodong {
    mask-image: url(/assets/images/icons/qhe_laodong.svg);
  }
  &.mci-tuhoso_online {
    mask-image: url(/assets/images/icons/tuhoso_online.svg);
  }
  &.mci-home {
    mask-image: url(/assets/images/icons/home.svg);
  }
  &.mci-thue {
    mask-image: url(/assets/images/icons/Thue.svg);
  }
}
