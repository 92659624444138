// Import
@import 'colors';
@import 'base';
@import 'button';
@import 'icons';
@import 'mcredit';
@import 'material';
@import 'input';
@import 'cdk';
// @import 'trihm';
// @import 'quynh';
.cdk-visually-hidden {
  height: 0;
  display: none;
}

// BREADCRUMB
.breadcrumb {
  height: 56px;
  margin-top: -16px;
  padding: 10px 0;
  display: flex;
  align-items: end;
  box-sizing: border-box;
  .text,
  .dash {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    color: $neutral-2;
  }
  .dash {
    margin: 0 6px;
    color: $neutral-3;
  }
  .title {
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;
  }
}

// CARD
mat-card.mat-card {
  box-shadow: none !important;
}

// CDK DIALOG

// .cdk-overlay-container {
//   pointer-events: none;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   position: fixed;
//   z-index: 1000;
//   .cdk-overlay-pane {
//     position: absolute;
//     pointer-events: auto;
//     box-sizing: border-box;
//     z-index: 1000;
//     display: flex;
//     max-width: 100%;
//     max-height: 100%;
//   }
// }
