:root {
    --primary: #00479D;
    --dark-primary: #0A58CA;
    --light-primary: #3180F4;
    --light-grey: #DBE9FF;
    --background: #EDEFF7;
    --neutral-3: #A7B0BA;
    --white: #ffffff;
}

// Custom
$white: #fff !default;
$transparent: transparent !default;
$primary-0: #141ED2 !default;
$primary-1: #0A73F0 !default;
$primary-2: #00B4FA !default;
$primary-3: #9DC7F9 !default;
$primary-4: #E7F1FD !default;
$primary-5: #F3F8FE !default;
$secondary-1: #0B7CC3 !default;
$secondary-2: #21ADE5 !default;
$neutral-1: #031D3B !default;
$neutral-2: #4F6176 !default;
$neutral-3: #A7B0BA !default;
$neutral-4: #E6E8EB !default;
$neutral-5: #F2F4F5 !default;
$red-1: #FD4A1E !default;
$red-2: #FE8061 !default;
$red-3: #FEA48E !default;
$red-4: #FFD9D0 !default;
$red-5: #FFF6F4 !default;
$green-1: #0DB55A !default;
$green-2: #ADE890 !default;
$green-3: #D6F3C7 !default;
$green-4: #E7F8EE !default;
$green-5: #F3FBF7 !default;
$orange-1: #FF9A3C !default;
$orange-2: #FFB877 !default;
$orange-3: #FFDCBB !default;
$orange-4: #FFF5EB !default;
$orange-5: #FFFAF5 !default;
$purple-1: #989AFB !default;
$purple-2: #B7B8FC !default;
$purple-3: #DBDCFE !default;
$purple-4: #F5F5FF !default;
$purple-5: #FAFAFF !default;
$bg-1: #E8F3FF !default;
$bg-2: #C8D8FF !default;
$bg-3: #8DA6FF !default;
$shadow-1: 1px 4px 6px rgba(26, 28, 38, 0.1);
$shadow-2: 1px 4px 12px rgba(26, 28, 38, 0.2);
$shadow-3: #b0b0b0;

$highlight: #0A73F0;