// @import '~@angular/material/theming';
// @import "~material-icons/iconfont/material-icons.css";
// @import "~line-awesome/dist/line-awesome/css/line-awesome.min.css";
@import 'colors';
// Mcredit
// Custom style
html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  color: $neutral-1;
  font-size: 14px;
  background-color: #eef0f8;
  font-family: Lato, Roboto, 'Helvetica Neue', sans-serif;
}

.container {
  // background: $white;
}

@-ms-viewport {
  width: device-width;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #a1a5af;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #efefef;
  border-radius: 5px;
  margin-left: 5px;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

p,
.p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

small,
.caption {
  font-size: 12px;
  line-height: 16px;
}
small {
  font-weight: 400;
}
.caption {
  font-weight: 700;
}

b,
strong {
  font-weight: 600;
}

.mat-checkbox-label {
  white-space: normal !important;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

a,
.link {
  color: $primary-2;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
}

a:hover,
a:active,
.link:hover,
.link:active {
  color: $primary-1;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

*:focus {
  outline: none;
}

ul,
ol,
li {
  list-style: none;
  padding: 0;
}

hr {
  border: none;
  margin-bottom: 16px;
}

.d-line {
  background-color: rgba($neutral-4, 0.5);
  display: block;
  height: 1px;
  width: 100%;
}

.d-section {
  position: relative;
  display: block;
  height: 3px;
  width: 100%;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 60px;
    height: 3px;
    border-radius: 2px;
    bottom: 4px;
    left: 50%;
    margin-left: -30px;
    background-color: $primary-1;
  }
}

.required::after {
  content: '*';
  margin-left: 3px;
  color: $red-1;
}

.transition {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, opacity, width, max-width, min-width;
}

// CARD
.mat-card {
  .mat-card-footer {
    margin: 0 0 -4px 0;
  }
}

// Custom Login form
.login-form {
  .mat-form-field-wrapper {
    margin-bottom: 0;
  }
  .mat-input-element {
    font-size: 16px;
  }
  .mat-form-field {
    margin-bottom: 0px;
    .mat-form-field-infix {
      padding: 22px 0 8px !important;
    }
    .mat-form-field-underline {
      background-color: $neutral-4;
    }
    .mat-form-field-label {
      color: $neutral-3;
    }
  }
}

// Custom Header
app-header {
  display: block;
  height: 56px;
}

// Paginator
.mat-paginator-container {
  height: 56px;
  margin-top: 8px;
  padding: 0 16px !important;
  .mat-paginator-page-size {
    // height: 48px;
    .mat-paginator-page-size-select {
      margin-top: 0;
    }
    .mat-form-field-wrapper {
      margin-bottom: 0;
      padding-bottom: 0;
      .mat-form-field-infix {
        border-top: none;
        padding-top: 5px;
      }
      .mat-form-field-underline {
        bottom: 0;
      }
    }
  }
}

// Step
.mat-stepper-horizontal {
  .mat-step-header {
    padding: 16px !important;
    border-radius: 8px;
    .mat-step-label {
      padding-top: 8px !important;
      font-size: 14px;
      color: $neutral-2;
      &.mat-step-label-selected {
        color: $primary-1;
        font-weight: 400;
      }
    }
    .mat-step-icon {
      height: 32px;
      width: 32px;
      background-color: $neutral-3;
      font-size: 16px;
      &.mat-step-icon-selected,
      &.mat-primary {
        background-color: $primary-1;
      }
      &.mat-step-icon-state-edit {
        background-color: $green-1;
        .mat-icon {
          color: #fff;
          background: transparent !important;
        }
      }
    }
  }
  .mat-horizontal-stepper-header-container {
    padding: 12px 80px;
    margin: 0 auto;
    background: $bg-1;
  }
}

.section-title {
  display: block;
  width: 100%;
  margin: 0 !important;
  padding: 10px 0 18px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 80px;
    height: 2px;
    left: 0;
    bottom: 12px;
    background-color: $neutral-4;
    border-radius: 2px;
  }
}

.mat-typography {
  .sub-title {
    display: block;
    width: 100%;
    margin: 8px 0 !important;
    padding: 0 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: $primary-1;
  }
  .sub-title-2 {
    display: block;
    width: 100%;
    margin: 8px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
  }
  .mini-title {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 8px;
    &.required {
      &:after {
        content: '*';
        width: 6px;
        text-align: center;
        margin-left: 3px;
        color: $red-1;
        display: inline-block;
        font-size: 12px;
        top: -2;
        position: relative;
      }
    }
  }
}

// Mat icon
mat-icon {
  cursor: pointer;
  &:hover {
    background-color: $neutral-3;
    border-radius: 5px;
  }
}

// List checkbox
.list-checkbox {
  li {
    padding: 4px 0;
  }
}

// Page title
.mat-typography .page-tittle,
.page-tittle {
  color: $neutral-1;
  font-size: 24px;
  padding: 24px 0 36px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 80px;
    height: 2px;
    left: 50%;
    bottom: 26px;
    margin-left: -40px;
    background-color: $neutral-4;
    border-radius: 1px;
  }
}

.mat-accordion .mat-expansion-panel {
  box-shadow: none;
  border: 1px solid $neutral-4;
  border-radius: 8px !important;
  margin: 0;
  & > .mat-expansion-panel-content > .mat-expansion-panel-body {
    // margin: 0 0 16px 0;
    .mat-expansion-panel-body {
      margin-bottom: 0;
    }
  }
}

.panel-title {
  font-weight: 500;
  color: $neutral-1;
}

.mat-expansion-panel-header > .mat-expansion-indicator:after {
  border-width: 0 1px 1px 0;
  margin-top: -6px;
  color: $neutral-3;
  // transform: rotate(-45deg);
}

.mat-expansion-panel-header.mat-expanded > .mat-expansion-indicator:after,
.mat-expansion-panel-header:hover > .mat-expansion-indicator:after {
  color: $primary-1;
  // transform: rotate(-135deg);
}

//Loading screen css
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;
    -webkit-animation: spin 2s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 1001;
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #e74c3c;
      -webkit-animation: spin 3s linear infinite;
      /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite;
      /* Chrome, Firefox 16+, IE 10+, Opera */
    }
    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f9c922;
      -webkit-animation: spin 1.5s linear infinite;
      /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite;
      /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
  .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #222;
    z-index: 1000;
    &.section-left {
      left: 0;
    }
    &.section-right {
      right: 0;
    }
  }
  &.done {
    visibility: visible;
    -webkit-transform: translateY(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%);
    /* IE 9 */
    transform: translateY(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.3s 1s ease-out;
    /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
    transition: all 0.3s 1s ease-out;
    /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
    .loader-section {
      &.section-left {
        -webkit-transform: translateX(-100%);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(-100%);
        /* IE 9 */
        transform: translateX(-100%);
        /* Firefox 16+, IE 10+, Opera */
        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
      }
      &.section-right {
        -webkit-transform: translateX(100%);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(100%);
        /* IE 9 */
        transform: translateX(100%);
        /* Firefox 16+, IE 10+, Opera */
        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
      }
    }
    .loader {
      display: none;
      opacity: 0;
      -webkit-transition: all 0.3s ease-out;
      /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
      transition: all 0.3s ease-out;
      /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
    }
  }
}

// Page container
.page-title {
  position: relative;
  & > span {
    color: $neutral-3;
    font-size: 16px;
    &.bread-line {
      font-size: 14px;
      margin: 0 6px;
    }
  }
  &.title-label {
    padding-bottom: 12px;
    height: auto !important;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 60px;
      height: 3px;
      border-radius: 2px;
      bottom: 4px;
      left: 50%;
      margin-left: -30px;
      background-color: $primary-1;
    }
  }
}

// Page Container
.page-container {
  &.page-data {
    width: 100%;
    height: 100%;
    .drawer-container {
      height: calc(100vh - 72px);
      max-height: calc(100vh - 88px);
    }
    & > .mat-drawer-container {
      overflow: visible;
      .mat-drawer-content.mat-sidenav-content {
        overflow: visible;
      }
    }
    mat-card {
      .mat-card-title {
        margin-bottom: 0;
      }
      mat-toolbar {
        .mat-icon-button {
          height: 36px;
          width: 36px;
          line-height: 36px;
          .mat-button-wrapper {
            display: block;
            line-height: 24px;
          }
        }
      }
      .toolbar-title {
        padding: 0;
        background-color: $white;
        .subheading-2 {
          padding-bottom: 15px;
        }
      }
      .toolbar-control {
        background-color: $white;
        height: 56px;
      }
    }
    .btn-refresh {
      border-radius: 6px;
      // background-color: $primary-4;
      color: $primary-1;
    }
    td .btn-refresh {
      background-color: $primary-4;
    }
    .btn-add {
      border-radius: 6px;
      background-color: $primary-1;
      color: $white;
      &:hover {
        background-color: $primary-2;
      }
    }
    .title-note {
      background-color: $primary-1;
      color: white;
      padding: 10px 10px 1px 10px;
      height: 90px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    mat-card.mat-card.mat-focus-indicator {
      height: auto;
      overflow: hidden;
    }
    mat-card.mat-card {
      .mat-card-content {
        height: auto;
        .tenjin-container {
          height: calc(100vh - 248px);
          overflow-y: auto;
        }
      }
    }
  }
}

// Filters table
.form-search {
  position: relative;
  .mat-icon-button {
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent !important;
    color: $primary-1 !important;
    line-height: 24px !important;
  }
  .search-box {
    border-radius: 4px;
    height: 36px;
    padding-left: 36px;
    border: 1px solid rgba($neutral-3, 0.5);
    padding-right: 16px;
    width: 260px;
    font-size: 14px;
    &:focus,
    &._focused,
    &.active {
      border-color: $primary-2;
      & + .mat-icon-button {
        color: $primary-1;
      }
    }
    &::placeholder {
      color: $neutral-2;
      // font-size: 12px;
      font-weight: 300;
      line-height: 34px;
    }
  }
}

.filters-input {
  display: flex;
  flex-direction: row;
  gap: 20px;
  & > * {
    display: flex;
    flex: 1;
  }
}

.filters {
  display: flex;
  flex-direction: row;
  place-content: space-between;
  .filter-left,
  .filter-right {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  .filter-left {
    place-content: start;
    flex-flow: row wrap;
    & > div {
      margin-right: 16px;
    }
    .filter-row {
      // &>div {
      //     margin-right: 16px;
      // }
      align-items: center;
    }
  }
  .filter-right {
    place-content: end;
    padding-left: 16px;
    align-items: end;
    button {
      margin-left: 4px;
    }
  }
  label,
  .filter-label {
    color: $neutral-2;
    font-size: 13px;
    font-weight: 400 !important;
    margin-right: 8px;
  }

  // Custom input
  // .mat-form-field {
  //     .mat-form-field-wrapper {
  //         margin-bottom: 0;
  //         padding-bottom: 0;
  //         .mat-input-element {
  //             font-size: 14px;
  //             padding: 0px 8px;
  //             &::placeholder {
  //                 font-size: 12px;
  //                 color: $neutral-3;
  //                 line-height: 20px;
  //             }
  //         }
  //         .mat-form-field-underline {
  //             bottom: 0;
  //             display: none;
  //         }
  //         .mat-form-field-infix {
  //             border: 1px solid $neutral-3;
  //             border-radius: 4px;
  //             padding: 7px 0px !important;
  //             line-height: 20px;
  //             .mat-select {
  //                 line-height: 16px;
  //             }
  //         }
  //         .mat-form-field-label-wrapper {
  //             padding: 0;
  //             top: -8px;
  //             height: 16px;
  //         }
  //         .mat-form-field-label {
  //             left: 6px;
  //             top: 0;
  //             span {
  //                 background: $white;
  //                 padding: 0 4px;
  //                 color: $neutral-2;
  //             }
  //         }
  //     }
  //     .mat-select-trigger {
  //         padding: 0 8px;
  //         line-height: 20px;
  //         .mat-select-value {
  //             font-size: 14px;
  //         }
  //         .mat-select-arrow-wrapper {
  //             position: relative;
  //             top: 0px;
  //             color: $primary-3;
  //         }
  //     }
  //     &.mat-focused {
  //         .mat-form-field-wrapper {
  //             .mat-form-field-infix {
  //                 border-color: $primary-2;
  //             }
  //         }
  //     }
  // }
}

.mat-form-field {
  .mat-select-trigger {
    padding: 0 8px;
    line-height: 20px;
    .mat-select-value {
      font-size: 14px;
    }
    .mat-select-arrow-wrapper {
      position: relative;
      top: 0px;
      color: $primary-3;
    }
  }
}

// Table
table {
  &.mat-table {
    width: 100%;
    border-collapse: separate;
    thead {
      tr {
        th {
          // background-color: rgba($primary-4, 0.5);
          background-color: $primary-5;
        }
      }
    }
    tr {
      &.mat-header-row {
        height: 36px;
        .mat-sort-header-content,
        .mat-header-cell {
          font-weight: 500;
          position: relative;
          text-align: center;
          .mat-sort-header-arrow {
            color: $primary-2;
          }
        }
      }
      &.mat-row {
        height: auto;
        // &:nth-child(even) {
        //     background-color: rgba($primary-4, 0.1);
        // }
      }
      &.mat-footer-row {
        th {
          background-color: $primary-5;
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: transparent;
            border-top: 1px solid rgba($neutral-3, 0.2);
            border-right: 1px solid rgba($neutral-3, 0.2);
          }
        }
      }
    }
    td,
    th {
      padding: 4px 16px;
      border-collapse: collapse;
      text-align: left;
    }
    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 8px;
    }
    th {
      background-color: transparent;
      height: 36px;
      font-weight: 500;
      &:last-child {
        border-right: none;
      }
      &.mat-header-cell {
        color: $neutral-1;
        font-size: 14px;
        padding: 4px 8px;
        font-weight: 500;
        border-right: 1px solid rgba($neutral-4, 0.8);
        border-bottom: 1px solid rgba($neutral-4, 0.8);
        // border-top: 1px solid rgba($neutral-4, 0.8);
        &:last-child {
          border-right: none;
          color: $neutral-1;
        }
        small {
          opacity: 0;
        }
      }
    }
    td {
      &.mat-cell {
        border-bottom-color: rgba($neutral-4, 0.95);
        padding: 4px 8px;
        color: $neutral-1;
        font-weight: 400;
        border-right: 1px solid rgba($neutral-4, 0.95);
        &:last-child {
          border-right: none;
        }
      }
      &.mat-table-sticky {
        background: $white;
        &:after {
          content: unset;
          // content: '';
          display: block;
          position: absolute;
          left: 0px;
          top: 0px;
          // width: calc(100% + 1px);
          // height: calc(100% + 1px);
          width: 100%;
          height: 100%;
          background: transparent;
          border-bottom: 1px solid rgba($neutral-4, 0.8);
          border-right: 1px solid rgba($neutral-4, 0.8);
        }
      }
      &.action-col {
        text-align: right !important;
        white-space: nowrap;
        width: 1%;
        padding-right: 8px !important;
        button {
          margin: 0 2px;
        }
      }
    }
  }
  // Table border
  &.border {
    border: 1px solid rgba($neutral-4, 0.8);
  }
  &.even {
    tr {
      &.mat-row {
        &:nth-child(even) {
          background-color: rgba($primary-4, 0.2);
          .mat-table-sticky {
            background: #f8fbff !important;
          }
        }
      }
    }
  }
  .mat-row:hover {
    background-color: #bbd0f42e;
  }
}

// Tabs
.mat-tab-body-content {
  padding: 16px;
}

.mat-tab-label {
  &.mat-tab-label-active {
    opacity: 1;
    .mat-tab-label-content {
      font-weight: 500;
      color: $primary-1;
    }
  }
}

// Dialog
// Dialog Size
.cdk-overlay-pane {
  // Dialog
  margin: auto;
  max-width: unset !important;
  &.small {
    max-width: 30vw !important;
  }
  &.medium {
    max-width: 50vw !important;
  }
  &.large {
    max-width: 80vw !important;
  }
  &.full {
    max-width: 100vw !important;
    height: 100vh !important;
    .mat-dialog-content {
      max-height: calc(100vh - 124px);
    }
  }
  &.primary {
    .mat-dialog-title {
      background-color: $primary-1;
      padding: 12px 24px;
      margin: -24px -24px 16px -24px;
      & > * {
        color: $white;
      }
      .mat-icon {
        &:hover {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }
  // Alert
  .mat-snack-bar-container {
    background-color: $white;
    color: $neutral-1;
    margin: 64px 16px 16px 16px;
    box-shadow: $shadow-1;
    .mat-button-wrapper {
      color: $neutral-2;
    }
    &.primary {
      background-color: $primary-1;
      color: $white;
      .mat-button-wrapper {
        color: $primary-3;
      }
    }
    &.secondary {
      background-color: $white;
      color: $primary-1;
      .mat-button-wrapper {
        color: $primary-3;
      }
    }
    &.success {
      background-color: $green-4;
      color: $green-1;
      .mat-button-wrapper {
        color: $green-2;
      }
    }
    &.danger {
      background-color: $red-4;
      color: $red-1;
      .mat-button-wrapper {
        color: $red-2;
      }
    }
    &.warn {
      background-color: $orange-4;
      color: $orange-1;
      .mat-button-wrapper {
        color: $orange-2;
      }
    }
  }
}

.mat-dialog-container {
  .mat-dialog-title {
    margin-bottom: 16px;
    display: flex;
    h2,
    h3 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      line-height: 28px !important;
    }
    .mat-icon {
      &.icon-back {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        &:hover {
          background: rgba($white, 0.2);
        }
      }
    }
    .icon-close {
      color: $neutral-3;
      margin-left: auto;
      align-self: center;
      &:hover {
        background-color: $neutral-4;
      }
    }
  }
  .mat-dialog-actions {
    margin-bottom: -20px;
    button {
      &[color='primary'] {
        min-width: 140px;
      }
    }
  }
}

.text-required::after {
  content: '*';
  color: red !important;
}
