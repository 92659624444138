@import 'styles/colors';
@import 'styles/hris';
@import './../node_modules/primeicons/primeicons.css';
@import './../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css';
@import './../node_modules/primeng/resources/primeng.min.css';
@import 'styles/prime/prime';

.p-ng {
  @import './../node_modules/primeflex/primeflex.scss';
}

.text-color-primary-1 {
  color: $primary-1;
}
.text-color-primary-2 {
  color: $primary-2;
}
.text-color-primary-3 {
  color: $primary-3;
}
.text-color-primary-4 {
  color: $primary-4;
}
.text-color-primary-5 {
  color: $primary-5;
}

.text-color-secondary-1 {
  color: $secondary-1;
}
.text-color-secondary-2 {
  color: $secondary-2;
}
.text-color-neutral-1 {
  color: $neutral-1;
}
.text-color-neutral-2 {
  color: $neutral-2;
}
.text-color-neutral-3 {
  color: $neutral-3;
}
.text-color-neutral-4 {
  color: $neutral-4;
}
.text-color-neutral-5 {
  color: $neutral-5;
}

/* You can add global styles to this file, and also import other style files */
mat-paginator {
  .mat-paginator-range-actions {
    button {
      background: unset !important;
    }
  }
  .mat-form-field .mat-select-trigger {
    padding: 0px 0px !important;
    text-align: center;
  }
}
// Start:  message -delete
app-confirmation.ng-star-inserted {
  display: unset !important;
  .mat-dialog-actions {
    justify-content: center;
    button {
      width: 120px;
      height: 40px;
    }
  }
}
// End:  message -delete
app-unit,
app-dependent-unit {
  form {
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      font-weight: 400 !important;
      line-height: 1.125 !important;
      height: 54px !important;
      padding-bottom: 1.25em !important;
    }
  }
}

// Start: css tree
orgchart-node {
  align-items: center;
  .oc-groups::before {
    content: '';
    box-sizing: border-box;
    top: 10px;
    left: 46px !important;
    position: absolute;
    border-color: #4f6176;
    border-style: solid;
    border-width: 0 0 2px 0;
  }
  .oc-groups > :host::before {
    content: '';
    box-sizing: border-box;
    top: 10px;
    left: 46px !important;
    position: absolute;
    border-color: #4f6176;
    border-style: solid;
    border-width: 0 0 2px 0;
  }
  .oc-group {
    .oc-node::before {
      content: '';
      box-sizing: border-box;
      left: 47%;
      top: -11px;
      bottom: -13px;
      height: 14px;
      margin-left: auto;
      margin-right: auto;
      width: 2px;
      position: absolute;
      background-color: #4f6176;
    }
  }
}
// End: css tree
mc-select-single-tree {
  .column-select {
    width: 100% !important;
    span {
      padding-bottom: 2.4px !important;
    }
  }
  .mat-form-field-outline {
    height: 54px !important;
    padding-top: 19px;
    color: #e6e8eb !important;
  }
  .mat-form-field-flex:hover,
  .mat-form-field-flex {
    .mat-form-field-outline-thick {
      color: #e6e8eb !important;
    }
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 1px !important;
    }
  }
  mat-nested-tree-node {
    .mat-nested-tree-node {
      min-width: 167px !important;
    }
  }
}
.mat-select-panel {
  max-width: unset !important;
}
// Start : checkbox
mat-checkbox {
  .mat-checkbox-frame {
    border: 1px solid $primary-1 !important;
    border-radius: 4px !important;
  }
}
// End : checkbox
mat-option,
mc-select-single-tree {
  width: 100% !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

app-before-confirmation {
  display: block !important;
}
mc-tree-checklist {
  .mat-form-field-underline {
    display: none !important;
  }
  .mat-form-field-infix {
    input {
      border: 1px solid #e6e8eb !important;
      border-radius: 5px !important;
      height: 35px !important;
    }
  }
  .mat-form-field-infix:active {
    input {
      border: 1px solid #4268a0 !important;
      border-radius: 5px !important;
      height: 35px !important;
    }
  }
  .mat-focused {
    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input {
      border: 1px solid #00b4fa !important;
      border-radius: 5px !important;
    }
  }
}

mat-dialog-container {
  max-height: 100vh !important;
}
app-work-place-changestatus,
app-unit-change-work,
app-confirm-add-duplicate-employee,
app-confirmation-add-contract {
  display: unset !important;
}

mat-form-field.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex .mat-input-element:disabled {
  color: rgb(0 0 0 / 40%) !important;
}
.mat-select-disabled .mat-select-value {
  color: rgb(0 0 0 / 40%) !important;
}

ngx-mat-tree-select-input-ngmodel {
  mat-form-field {
    width: 100% !important;
  }
  .mat-select-trigger {
    padding-bottom: 4px !important;
    box-sizing: border-box !important;
    height: 40px !important;
    padding-top: 8px !important;
    .mat-select-value {
      vertical-align: middle;
    }
    .mat-select-arrow-wrapper {
      vertical-align: middle;
      top: 8px !important;
    }
    .mat-form-field-outline {
      padding-top: 18px !important;
    }
  }
  // .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  //   padding-top: unset !important;
  // }
  // .mat-form-field-flex:hover {
  //   .mat-form-field-outline-start, .mat-form-field-outline-gap , .mat-form-field-outline-end {
  //     border: 1px solid transparent !important;
  //   }
  // }
}

app-role-assign-staff {
  :host {
    > .container {
      max-width: 1264px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      background: none;
    }
  }

  .container {
    position: relative;
    width: 100%;
    flex: 1 0 auto;
    margin: 0 auto;
    text-align: left;
  }

  #content {
    box-sizing: content-box;
    margin: 0 auto;
    padding: 0px !important;
    // max-width: 1100px;
    width: 100%;
  }

  #content,
  #main-content {
    &::before,
    &::after {
      content: '';
      display: table;
    }
    &::after {
      clear: both;
    }
    .mat-tab-group {
      flex-direction: inherit !important;
    }

    .mat-tab-header-pagination {
      display: none !important;
    }
    .mat-tab-labels {
      display: grid !important;
    }
    .mat-ink-bar {
      display: none !important;
    }
    .mat-tab-body-wrapper {
      width: 80vw !important;
      flex: 1 1 auto;
      border-radius: 8px;
      box-sizing: border-box;
      margin-left: 20px;
    }

    .mat-tab-label.mat-tab-label-active {
      opacity: 1;
      background: aliceblue;
    }

    .mat-tab-label {
      justify-content: flex-start !important;
      border-bottom: 1px solid #a58a8a5c !important;
    }

    .mat-tab-header {
      display: flex;
      overflow: hidden;
      position: relative;
      flex-shrink: 0;
      background-color: #ffffff !important;
      padding: 10px;
      border-radius: 8px;
      box-sizing: border-box;
      width: 17vw;
    }
    .mat-tab-body-content {
      height: unset !important;
      padding: 0px !important;
    }
  }
}

app-employee-info,
app-approve-person-change-info-detail {
  :host {
    > .container {
      max-width: 1264px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      background: none;
    }
  }

  .container {
    position: relative;
    width: 100%;
    flex: 1 0 auto;
    margin: 0 auto;
    text-align: left;
  }

  #content {
    box-sizing: content-box;
    margin: 0 auto;
    padding: 0px !important;
    // background-color: #ffffff;
    // max-width: 1100px;
    width: 100%;
  }

  #content,
  #main-content {
    &::before,
    &::after {
      content: '';
      display: table;
    }
    &::after {
      clear: both;
    }
    .mat-tab-group {
      flex-direction: inherit !important;
    }

    .mat-tab-header-pagination {
      display: none !important;
    }
    .mat-tab-labels {
      display: grid !important;
    }
    .mat-ink-bar {
      display: none !important;
    }
    .mat-tab-body-wrapper {
      width: 80vw !important;
      flex: 1 1 auto;
      border-radius: 8px;
      box-sizing: border-box;
      margin-left: 20px;
    }

    .mat-tab-label.mat-tab-label-active {
      opacity: 1;
      background: aliceblue;
    }

    .mat-tab-label {
      justify-content: flex-start !important;
      border-bottom: 1px solid #a58a8a5c !important;
    }

    .mat-tab-header {
      display: flex;
      overflow: hidden;
      position: relative;
      flex-shrink: 0;
      background-color: #ffffff !important;
      padding: 10px;
      border-radius: 8px;
      box-sizing: border-box;
      width: 17vw;
    }
    .mat-tab-body-content {
      height: unset !important;
      padding: 0px !important;
    }
  }
}
app-personal-information {
  .mat-form-field-flex .mat-form-field-infix {
    width: 100% !important;
  }
  .title-info {
    font-size: 14px;
    color: $secondary-1;
  }
}
.title-info {
  font-size: 14px;
  color: $secondary-1;
}
app-union-members,
app-personal-information,
app-reward,
app-other {
  #menu-union-content {
    .mat-tab-group {
      flex-direction: column !important;
    }
    .mat-tab-labels {
      display: flex !important;
    }
    .mat-tab-header {
      width: 100% !important;
      padding: unset !important;
      border-radius: unset !important;
    }
    .mat-tab-body-wrapper {
      margin-left: 0px !important;
      width: 100% !important;
      flex: 1 1 auto;
    }
    table {
      width: calc(100% - 2px);
    }
    .mat-tab-label {
      justify-content: center !important;
      // border-bottom: unset !important;
      height: 40px;
      border: 1px solid #624e4e24;
    }
    .mat-tab-label.mat-tab-label-active {
      border-bottom: 1px solid #0a73f0;
    }
  }
}

app-management-info {
  #menu-unit {
    .mat-tab-group {
      flex-direction: column !important;
    }
    .mat-tab-labels {
      display: flex !important;
    }
    .mat-tab-header {
      width: 100% !important;
      padding: unset !important;
      border-radius: unset !important;
    }
    .mat-tab-body-wrapper {
      margin-left: 0px !important;
      width: 100% !important;
      flex: 1 1 auto;
    }
    table {
      width: calc(100% - 2px);
    }
    .mat-tab-label {
      justify-content: center !important;
      // border-bottom: unset !important;
      height: 40px;
      border: 1px solid #624e4e24;
    }
    .mat-tab-label.mat-tab-label-active {
      border-bottom: 1px solid #0a73f0;
    }
  }
}

app-certificate {
  #certificate-list {
    .mat-tab-labels {
      display: flex !important;
    }
    .mat-tab-header {
      width: 550px;
      border-bottom: 0px;
    }
    .mat-tab-group {
      flex-direction: column !important;
    }
    .mat-tab-body-wrapper {
      width: 98% !important;
    }
    .mat-tab-label {
      justify-content: center !important;
    }
  }
}

//Chỉnh css mat-button mat-menu (table)
.cdk-overlay-container {
  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      position: absolute !important;
    }
  }
}
app-contract-authorization {
  .center .mat-sort-header-container {
    justify-content: center !important;
  }
  .mat-sort-header-content:after {
    display: none !important;
  }
}
app-employee-self-assessment {
  .mat-stepper-horizontal .mat-horizontal-stepper-header-container {
    background: unset !important;
    padding: 0px !important;
  }
  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background-color: unset !important;
  }
  .mat-stepper-horizontal .mat-step-header {
    display: block !important;
    text-align: -webkit-center !important;
  }
  // .mat-stepper-horizontal .mat-step-header .mat-step-icon {
  //   background-color: #ffffff00 !important;
  //   border: 1px solid black !important;
  // }
  .mat-stepper-horizontal .mat-step-header .mat-step-icon {
    background-color: #077b06d1 !important;
    border: 1px solid black !important;
  }
}

// Set css input-date-ngxDaterangepickerMd
.mat-form-field-label {
  color: #4f6176 !important;
}
.input-ngxDaterangepickerMd {
  input {
    width: calc(100% - 32px);
    height: 36px;
    padding-bottom: 11px;
    box-sizing: border-box;
    margin-top: 3px;
    border: 1px solid #e6e8eb;
    border-radius: 4px;
  }
  a {
    vertical-align: -webkit-baseline-middle;
  }
}

.width-150 {
  .mat-form-field {
    width: 150px !important;
  }
}

.width150 {
  width: 150px !important;
}

.width-100 {
  .mat-form-field {
    width: 100px !important;
  }
}

app-loading {
  #loading-bar {
    position: relative;
    width: 100%;
    height: 8px;
    display: block;
    background-color: #dbe9ff;
  }

  .animation {
    width: 0px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .bar-1 {
    background-color: #00b4fa;
    animation: bar1 4s infinite;
    z-index: 9;
  }

  .bar-2 {
    background-color: #141ed2;
    animation: bar2 4s infinite;
    z-index: 10;
  }

  .bar-3 {
    background-color: #00b4fa;
    animation: bar3 4s infinite;
    z-index: 11;
  }

  .bar-4 {
    background-color: #dbe9ff;
    animation: bar4 4s infinite;
    z-index: 12;
  }

  @keyframes bar1 {
    0% {
      width: 0%;
    }
    25% {
      width: 100%;
    }
    50% {
      width: 100%;
    }
    75% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes bar2 {
    0% {
      width: 0%;
    }
    25% {
      width: 0%;
    }
    50% {
      width: 100%;
    }
    75% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes bar3 {
    0% {
      width: 0%;
    }
    25% {
      width: 0%;
    }
    50% {
      width: 0%;
    }
    75% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes bar4 {
    0% {
      width: 0%;
    }
    25% {
      width: 0%;
    }
    50% {
      width: 0%;
    }
    75% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  /* loading img */

  #loading-img {
    justify-content: center;
    align-items: center;
    display: flex;
    display: block;
    box-sizing: border-box;
    height: 48px;
    width: 80px;
    margin: 0 auto;
    background-color: #f3f8fe;
    padding: 50px 12px;
    border-radius: 8px;
  }

  #loading-img .loadsquare {
    display: inline-block;
    width: 8px;
    height: 16px;
    border-radius: 2px;
    margin: 0 4px;
    background: #0a73f0;
    animation: loadingImgScale 1.5s ease;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  #loading-img .square-1 {
    background-color: #141ed2;
    animation-delay: 0s;
  }

  #loading-img .square-2 {
    background-color: #0a73f0;
    animation-delay: 0.5s;
  }

  #loading-img .square-3 {
    background-color: #00b4fa;
    animation-delay: 1s;
  }

  @keyframes loadingImgScale {
    0% {
      transform-origin: bottom top;
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
}

.contact-assessment-personnel {
  .mat-sort-header-container {
    width: 165px !important;
  }
}

.contact-assessment-group {
  .mat-sort-header-container {
    width: 165px !important;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: transparent !important;
  color: black;
  + .mat-step-label {
    color: black !important;
  }
}
.mat-step-header .mat-step-icon-selected {
  background-color: transparent !important;
  color: black;
  + .mat-step-label {
    color: black !important;
  }
}
.mat-step-header .mat-step-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}

app-contract-evaluation-lifecycle {
  width: 100%;
  background: #f3f8fe;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .horizontal-stepper {
    // background-color: white;
    display: flex;
    width: 100%;
    .horizontal-stepper-header-container-customer {
      align-items: flex-start;
      // padding: 12px 80px;
      margin: 0 auto;
      background: unset !important;
      white-space: nowrap;
      // display: flex;
      width: 100%;
      .note-itemmat-step-header {
        box-sizing: border-box;
        flex-direction: column;
        height: auto;
        padding: 16px 0px !important;
        border-radius: 8px;
        overflow: hidden;
        outline: none;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .content-mat-step-header-ripple {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          pointer-events: none;
          display: block;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          white-space: nowrap;
        }
        .content-mat-step-icon {
          margin-right: 0;
          margin-left: 0;
          height: 36px;
          width: 36px;
          font-size: 16px;
          flex: none;
          border-radius: 50%;
          position: relative;
          .mat-step-icon-content-customer {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
          }
        }
        .content-mat-step-icon.notOperated {
          background-color: white !important;
          border: 2px solid #e7f1fd;
          box-sizing: border-box;
        }
        .content-mat-step-icon.operated {
          background-color: $primary-1 !important;
        }
        .content-mat-step-icon.accept {
          background-color: green !important;
          border: 2px solid green;
          box-sizing: border-box;
        }
        .content-mat-step-icon.unAccepted {
          background-color: #fd4a1e !important;
          border: 2px solid #fd4a1e;
          box-sizing: border-box;
        }
        .content-mat-step-icon.return {
          background-color: #fd4a1e !important;
          border: 2px solid #fd4a1e;
          box-sizing: border-box;
        }
        .content-mat-step-icon.returned {
          background-color: #ff9a3c !important;
          border: 2px solid #ff9a3c;
          box-sizing: border-box;
        }

        .content-step-label-title {
          padding: 0;
          padding-top: 8px !important;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          width: 100%;
          color: black !important;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 50px;
          vertical-align: middle;
        }
        &::after {
          right: 0;
        }
        &::before {
          left: 0;
        }
      }
      .customer-mat-stepper-horizontal-line {
        margin: 0;
        min-width: 0;
        position: relative;
        top: 36px;
        border-top-width: 1px;
        border-top-style: solid;
        flex: auto;
        height: 0;
        border-top-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .horizontal-stepper .note-itemmat-step-header.accessment:not(:last-child)::after {
    content: '';
    display: inline-block;
    height: 2px;
    position: absolute;
    width: calc(50% - 20px);
    top: 36px;
    background: rgb(24 23 23 / 71%);
  }

  .horizontal-stepper:not(:last-child)
    .horizontal-stepper-header-container-customer.isAccessment
    .note-itemmat-step-header:not(:last-child)::after {
    content: '';
    display: inline-block;
    height: 1px;
    position: absolute;
    width: calc(50% - 20px);
    top: 36px;
    background: rgba(24, 23, 23, 0.278);
  }

  .horizontal-stepper:not(:first-child)
    .horizontal-stepper-header-container-customer.isAccessment
    .note-itemmat-step-header:not(:last-child)::before {
    left: 0;
    content: '';
    display: inline-block;
    position: absolute;
    width: calc(50% - 20px);
    top: 36px;
    height: 2px;
    background: rgb(24 23 23 / 71%);
  }

  .horizontal-stepper:not(:first-child) .note-itemmat-step-header.accessment::before {
    left: 0;
    content: '';
    display: inline-block;
    position: absolute;
    width: calc(50% - 20px);
    top: 36px;
    height: 2px;
    background: rgb(24 23 23 / 71%);
  }
  .horizontal-stepper .note-itemmat-step-header.notOperated:not(:last-child)::after {
    content: '';
    display: inline-block;
    height: 1px;
    position: absolute;
    width: calc(50% - 20px);
    top: 36px;
    background: rgba(24, 23, 23, 0.278);
  }

  .horizontal-stepper:not(:first-child) .note-itemmat-step-header.notOperated::before {
    left: 0;
    content: '';
    display: inline-block;
    position: absolute;
    width: calc(50% - 20px);
    top: 36px;
    height: 1px;
    background: rgba(24, 23, 23, 0.278);
  }

  .mat-step-icon-content-customer {
    i {
      color: white !important;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .mat-step-text-label-customer {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    text-align: center;
    color: $primary-1 !important;
    font-size: 14px;
    white-space: nowrap;
  }
  .mat-step-text-label-customer-titleName {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    text-align: center;
    color: $neutral-2 !important;
    font-size: 12px;
    white-space: nowrap;
  }
}
.cdk-overlay-connected-position-bounding-box {
  left: 14px !important;
}

.table-change-type-contract {
  .mat-sort-header-container {
    justify-content: center !important;
  }
  .width190 {
    width: 190px !important;
  }
  tr.mat-header-row .mat-sort-header-content:after,
  tr.mat-header-row .mat-header-cell:after {
    display: none !important;
  }
}

// css droplist select
.mat-select-panel-wrap {
  .mat-select-panel {
    margin-top: 36px !important;
  }
}
// Chỉnh CSS Input date icon canlendar không có background
.mat-datepicker-toggle {
  .mat-icon-button[disabled='true'] {
    background-color: unset !important;
  }
}
.input-action-search {
  width: calc(100% - 46px) !important;
}

.filters-input {
  .label-input {
    .mat-form-field-wrapper .mat-form-field-flex {
      height: 36px !important;
    }
    .mat-form-field-flex {
      align-items: unset !important;
    }
  }
}
.gap-40 {
  gap: 40px !important;
}

input::placeholder {
  color: #353644;
  font-weight: 300;
  line-height: 34px;
}
app-profile-user,
app-onboarding-candidate {
  .category {
    border-radius: 8px;
  }
  .accordion-headers-align {
    .mat-expansion-panel-header {
      padding-left: 0px !important;
      .mat-content {
        border-left: 5px solid #0a73f0;
        height: 30px;
        font-size: 14px;
        line-height: 20px;

        .mat-expansion-panel-header-title {
          align-items: center;
          padding-left: 4px;
          text-transform: uppercase;
          color: #0a73f0;
        }
      }
    }
  }
  .mat-tab-body.mat-tab-body-active {
    height: 100% !important;
  }
}

.row-nodata {
  height: 48px;
  align-items: center;
  display: flex;
  &-content {
    position: absolute;
    left: 46%;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0a73f0;
}
