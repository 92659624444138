@import 'colors';
// Mat button
[class*='mat-button'] {
  &:not(.mat-icon-button),
  &:not(.mat-flat-button),
  &:not(.mat-stroked-button) {
    &[color='secondary'] {
      color: $primary-1;
      .mat-icon {
        color: $primary-1;
      }
    }
    &[color='warn'] {
      color: $orange-1;
      .mat-icon {
        color: $orange-1;
      }
    }
    &[color='danger'] {
      color: $red-1;
      .mat-icon {
        color: $red-1;
      }
    }
  }
  .mat-icon {
    line-height: 24px;
    font-size: 20px;
  }
  &.mat-flat-button:not(.mat-icon-button) {
    box-shadow: none;
    .mat-icon,
    i {
      margin-right: 4px;
      margin-left: -2px;
      margin-top: -2px;
    }
    i {
      font-size: 20px;
    }
    &:hover {
      opacity: 0.8;
      .mat-icon,
      i {
        background-color: transparent;
      }
    }
    .mat-button-wrapper {
      // line-height: 36px;
      // display: flex;
      // align-items: center;
      // place-content: center;
    }
    &[color='primary'] {
      background-color: $primary-1;
      color: $white;
      .mat-icon {
        color: $white;
      }
    }
    &[color='secondary'] {
      background-color: $primary-5;
      color: $primary-1;
      .mat-icon {
        color: $primary-1;
      }
    }
    &[color='warn'] {
      background-color: $orange-1;
      color: $white;
      .mat-icon {
        color: $white;
      }
    }
    &[color='danger'] {
      background-color: $red-1;
      color: $white;
      .mat-icon {
        color: $white;
      }
    }
    &[color='success'] {
      background-color: $green-1;
      color: $white !important;
      .mat-icon {
        color: #fff;
      }
    }
    &:not([color]) {
      background-color: $neutral-5;
      color: $neutral-2;
    }
    &[disabled='true'] {
      background-color: $neutral-3;
      color: $white;
      .mat-icon,
      i {
        color: $white;
        cursor: non;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  &.mat-stroked-button:not(.mat-icon-button) {
    box-shadow: none;
    .mat-icon,
    i {
      margin-right: 4px;
      margin-left: -2px;
      margin-top: -2px;
    }
    i {
      font-size: 20px;
    }
    &:hover {
      // opacity: 0.8;
      .mat-icon,
      i {
        background-color: transparent;
      }
    }
    .mat-button-wrapper {
      // line-height: 36px;
      // display: flex;
      // align-items: center;
      // place-content: center;
    }
    &[color='primary'] {
      border-color: $primary-1;
      color: $primary-1;
    }
    &[color='secondary'] {
      border-color: $primary-5;
      color: $primary-1;
      .mat-icon {
        color: $primary-1;
      }
    }
    &[color='warn'] {
      border-color: $orange-1;
      color: $orange-1;
    }
    &[color='danger'] {
      border-color: $red-1;
    }
    &[color='success'] {
      border-color: $green-1;
    }
    &:not([color]) {
      color: $neutral-2;
    }
    &[disabled='true'] {
      background-color: $neutral-3;
      color: $white;
      .mat-icon,
      i {
        cursor: none;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  // Mat icon button
  &.mat-icon-button {
    border-radius: 4px;
    height: 36px;
    width: 36px;
    line-height: 36px;
    .mat-icon,
    i {
      // margin: 0 auto;
      // display: flex;
      // place-content: center;
      margin-top: -1px;
    }
    i {
      font-size: 20px;
    }
    &[color='primary'] {
      background-color: $primary-1;
      .mat-icon,
      i {
        color: $white;
      }
      &:hover {
        background-color: $primary-2;
      }
      &:disabled {
        background-color: $neutral-3;
      }
    }
    &[color='secondary'] {
      background-color: $primary-5;
      .mat-icon,
      i {
        color: $primary-1;
      }
      &:hover {
        background-color: $primary-4;
      }
    }
    &[color='warn'] {
      background-color: $orange-1;
      .mat-icon,
      i {
        color: $white;
      }
      &:hover {
        background-color: $orange-2;
      }
    }
    &[color='danger'] {
      background-color: $red-1;
      .mat-icon,
      i {
        color: $white;
      }
      &:hover {
        background-color: $red-2;
      }
    }
    &[color='success'] {
      background-color: $green-1;
      .mat-icon,
      i {
        color: $white;
      }
      &:hover {
        background-color: $green-2;
      }
    }
    &:not([color]) {
      background-color: transparent;
      color: $neutral-2;
    }
    &:hover {
      background-color: $primary-5;
      &:hover {
        .mat-icon,
        i {
          background: transparent;
        }
      }
    }
    &[disabled='true'] {
      background-color: $neutral-3;
      .mat-icon,
      i {
        color: $white;
        cursor: default;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}

button,
button.mat-icon-button {
  // Size
  &[size='28'] {
    height: 28px;
    line-height: 28px;
    i,
    .mat-icon {
      font-size: 18px;
    }
  }
  &[size='32'] {
    height: 32px;
    line-height: 32px;
  }
  &[size='36'] {
    height: 36px;
    line-height: 36px;
  }
  &[size='40'] {
    height: 40px;
    line-height: 40px;
  }
  &[size='44'] {
    height: 44px;
    line-height: 44px;
  }
  &[size='48'] {
    height: 48px;
    line-height: 48px;
  }
}

button.mat-icon-button {
  &[size='28'] {
    width: 28px;
  }
  &[size='32'] {
    width: 32px;
  }
  &[size='36'] {
    width: 36px;
  }
  &[size='40'] {
    width: 40px;
  }
  &[size='44'] {
    width: 44px;
  }
  &[size='48'] {
    width: 48px;
  }
}

.mat-ripple-element {
  display: none !important;
}

.mat-button.mat-success {
  color: #0db55a;
  background-color: #f3fbf7;
  border-radius: 4px;
}
.mat-button.mat-success:hover {
  background-color: #f3fbf7;
}

.mat-button.mat-danger {
  color: #fd4a1e;
  background-color: #fff6f4 !important;
  border-radius: 4px;
}
.mat-button.mat-danger:hover {
  background-color: #fff6f4;
}
